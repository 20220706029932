import React, { Component } from 'react';
import { navigate } from 'gatsby';

import * as routes from '../../constants/routes';
import { withFirebase } from '../Firebase/FirebaseContext';
import { TextInput } from '../UI/TextInput';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        navigate(routes.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <TextInput
          onChange={event =>
            this.setState({ [event.target.name]: event.target.value })
          }
          value={email}
          name="email"
          type="text"
        />
        <TextInput
          onChange={event =>
            this.setState({ [event.target.name]: event.target.value })
          }
          value={password}
          name="password"
          type="password"
        />
        <button disabled={isInvalid} type="submit">
          Sign In
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

export default withFirebase(SignInForm);
