import React from 'react';
import styled from 'styled-components';
// import { Theme } from '../../theme';

const StyledTextInput = styled.input`
  border: none;
`;

export const TextInput = ({
  preventDefault = false,
  disabled = false,
  onChange,
  value,
  type,
  name,
  ...props
}) => {
  return (
    <StyledTextInput
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      placeholder="Email Address"
      {...props}
    />
  );
};
